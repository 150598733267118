.layout{
    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;
    height: 100vh;
    background: $colorBack;

    &__header{
        position: fixed;
        z-index: 2;
        top: 20px;
        left: 20px;
    }
    &__footer{
        position: fixed;
        z-index: 2;
        bottom: 20px;
        left: 20px;
        color: white;
        font-size: 0.8em;
        opacity: 0.6;
    }
    
    &__header-link{
        position: relative;
        display: block;
        color: white;
        text-decoration: none;
        &:hover{
            text-decoration: underline;
        }
    }

    &__title{
        display: block;
        color: white;
    }

    &__main{
        position: relative;
        z-index: 1;
        display: block;
        width: 100%;
        height: 100vh;
    }
    
    
    &__left{
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        overflow: hidden;
        width: 100%;
        height: 100vh;
    }
    &__right{
        position: absolute;
        z-index: 1;
        top: 0;
        left: 100%;
        width: 100%;
        height: 100vh;
        background: #1d2731;
        
    }
    &__top{
        position: absolute;
        z-index: 1;
        bottom: 100%;
        left: 0;
        width: 100%;
        height: 100vh;
        background: #0b3c5d;
    }
    &__top-right{
        position: absolute;
        z-index: 1;
        bottom: 100%;
        left: 100%;
        width: 100%;
        height: 100vh;
        background: #d9b410;
    }
}

#route
{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    transition: transform 0.2s ease-in 0.2s;
    will-change: transform;
    &.index {
        transform: translateX(0) translateY(0);
    }
    &.about {
        transform: translateX(0) translateY(100%);
    }
    &.portfolio{
        transform: translateX(-100%) translateY(0);
    }
    &.not-found{
        transform: translateX(-100%) translateY(100%);
    }
}
