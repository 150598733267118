.btn{
    color: blue;
}

.logo{
    display: block;
    width: 30px;
    height: 30px;
    @include background-image(logo);
}

.nav{
    &__item{
        display: block;
        color:white;
        text-decoration: none;
        &:hover{
            text-decoration: underline;
        }
    }
}
.page{
    padding:260px 20px 0px;
    color: white;
    h2{
        padding-bottom: 10px;
        &:last-child{
            padding-bottom: 0;
        }
    }
    p{
        padding-bottom: 10px;
        &:last-child{
            padding-bottom: 0;
        }
    }
    a{
        color: white;
        &:hover{
            text-decoration: none;
        }
    }
}